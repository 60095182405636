import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import Facebook from './icons/facebook';
import Twitter from './icons/twitter';
import Instagram from './icons/instagram';
import Youtube from './icons/youtube';

import { outer, inner, SocialLink } from '../styles/shared';
import config from '../website-config';

import { colors } from '../styles/colors';

import LazyLoad from 'react-lazyload';

const LuminarBanner = styled.img`
  margin-top: 7rem;
  margin-bottom: 4.5rem;
  box-shadow: 0 6px 10px 0px #4a286247;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;

  @media (max-width: 1200px) {
    max-width: 95%;
  }
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 10px;
  }
`;

const SiteFooter = css`
  position: relative;
  background: #F4F4F4;
  padding: 56px 0 40px;
  color: ${colors.omega};

  @media (max-width: 1200px) {
    padding: 32px 18px 40px;
  }

`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  line-height: 2;
  
  a {
    display: block;
    font-size: 2rem;
    text-decoration: ${colors.beta} 1px underline;
    transition: all 0.25s;
  }
  a:hover {
    text-decoration: ${colors.alpha} 1px underline;
  }

  .footerMainLinks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  .footer-col a{
    display: block;
    line-height: 30px;
  }

  .footer-col {
    min-width: 146px;

    @media (max-width: 1200px) {
      margin-bottom: 12px;
    }
  }

  .copyright {
    color: ${colors.omega};
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    display: flex;
  }

  .icon-reg {
    font-size: 26px;
    line-height: 33px;
    display: block;
    margin-right: 6px;
  }
 
`;

const SiteFooterNav = styled.nav`
  display: flex;
  align-items: center;

  a {
    position: relative;
    margin-left: 10px;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;

const Footer: React.FunctionComponent = () => {
  return (
    <div>
      <footer css={[outer, SiteFooter]}>
        <div css={[inner, SiteFooterContent]}> 
          <div>
            <div className='footerMainLinks'>
              <div className="footer-col">
                <a href="https://giggster.com/book/photo-shoot">Photo Shoot</a>
                <a href="https://giggster.com/book/photo-studio">Photo Studio</a>
              </div>

              <div className="footer-col">
                <a href="https://giggster.com/book/filming">Filming</a>
                <a href="https://giggster.com/book/film-shoot">Film Shoot</a>
              </div>

              <div className="footer-col">
                <a href="https://giggster.com/book/production">Production</a>
                <a href="https://giggster.com/book/video-shoot">Video Shoot</a>
              </div>

              <div className="footer-col">
                <a href="https://giggster.com/book/dance-shoot">Dance Shoot</a>
                <a href="https://giggster.com/book/film-studio">Film Studio</a>
              </div>

              <div className="footer-col">
                <a href="https://giggster.com/book/influencer">Influencer</a>
                <a href="https://giggster.com/book/green-screen">Green Screen</a>
              </div>

              {/*<Link to="/about/">About</Link> &nbsp; &#9676; &nbsp;
              <Link to="/now/">Now</Link> &nbsp; &#9676; &nbsp;
              <Link to="/credits/">Credits</Link> &nbsp; &#9676; &nbsp;
  <Link to="/contact/">Contact</Link> &nbsp; &#9676; &nbsp;

              <a
                href="https://www.iubenda.com/privacy-policy/79101513"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>*/}
            </div>
            <div className="copyright">
              <span className="icon-reg">&reg;</span> Giggster. Made in Los Angeles, CA
            </div>
          </div>
          <SiteFooterNav>
            <SocialLinks>
              <a
                css={SocialLink}
                href={config.instagram}
                target="_blank"
                title="Giggster on Instagram"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </SocialLinks>
          </SiteFooterNav>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
