export interface WebsiteConfig {
  title: string;
  
  description: string;
  coverImage: string;
  logo?: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  facebook?: string;
  twitter: string;
  youtube?: string;
  instagram: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
}

const config: WebsiteConfig = {
  title: 'Giggster',
  description: 'Photography and Photo Retouching Guides, Tips & Tricks',
  coverImage: 'img/blog-cover.jpg',
  siteUrl: 'https://giggster.com/guide',
  twitter: 'https://twitter.com/Purple11Photo',
  instagram: 'https://instagram.com/giggster_official/',
  //youtube: 'https://www.youtube.com/channel/UCkbQuTCQ3UPu9Z4_7BW-hIg',
  showSubscribe: false,
  mailchimpAction:
    'https://beee.us12.list-manage.com/subscribe/post?u=6846a3aafd166ef37d09ee6cc&amp;id=9544d5e6cd',
  mailchimpName: 'b_d67663468cc0f209db53cc6d5_c039a8181e',
};

export default config;
