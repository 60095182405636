// tslint:disable:no-http-string
import * as React from 'react';

import { colors } from '../../styles/colors';

const Instagram: React.FunctionComponent = () => (
  <svg width="18" height="18" viewBox="0 0 504 504" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M252 .2c-68.5 0-77 .2-104 1.5A184.9 184.9 0 0 0 87 13.4c-16.6 6.4-30.6 15-44.6 29s-22.6 28.1-29 44.7C7 103 2.6 121.4 1.4 148.2A1787 1787 0 0 0 0 252.1c0 68.4.3 77 1.5 103.8 1.2 26.9 5.5 45.2 11.7 61.2 6.5 16.6 15 30.6 29 44.6s28.1 22.6 44.7 29a185 185 0 0 0 61.2 11.8 1787 1787 0 0 0 103.8 1.5c68.4 0 77-.3 103.9-1.5a185 185 0 0 0 61.1-11.7c16.6-6.5 30.7-15 44.7-29 14-14 22.6-28.1 29-44.7a185 185 0 0 0 11.7-61.2 1787 1787 0 0 0 1.5-103.8c0-68.4-.2-77-1.5-103.9a185 185 0 0 0-11.7-61.1c-6.4-16.6-15-30.7-29-44.7-14-14-28.1-22.6-44.7-29-16-6.2-34.3-10.5-61.1-11.7A1787 1787 0 0 0 251.9.2zm0 45.4c67.2 0 75.2.2 101.7 1.4 24.6 1.1 38 5.2 46.8 8.7a78 78 0 0 1 29 18.8 78 78 0 0 1 18.8 29c3.5 8.9 7.6 22.2 8.7 46.8 1.2 26.5 1.4 34.5 1.4 101.8 0 67.2-.2 75.2-1.4 101.8a139.4 139.4 0 0 1-8.7 46.8 78 78 0 0 1-18.8 29 78 78 0 0 1-29 18.8c-8.9 3.4-22.2 7.5-46.8 8.6a1748 1748 0 0 1-101.8 1.5c-67.3 0-75.2-.2-101.8-1.5a139.4 139.4 0 0 1-46.8-8.6 78 78 0 0 1-29-18.9 78 78 0 0 1-18.8-29c-3.4-8.8-7.5-22.2-8.6-46.7-1.3-26.6-1.5-34.6-1.5-101.8 0-67.3.2-75.3 1.5-101.8 1-24.6 5.2-38 8.6-46.8a78 78 0 0 1 18.9-29 78 78 0 0 1 29-18.8c8.8-3.5 22.2-7.6 46.7-8.7 26.6-1.2 34.6-1.5 101.8-1.5z"
      fill={colors.alpha}
    />
    <path
      d="M252 336a84 84 0 1 1 0-167.9 84 84 0 0 1 0 168zm0-213.3a129.4 129.4 0 1 0 0 258.7 129.4 129.4 0 0 0 0-258.7zM416.6 117.6a30.2 30.2 0 1 1-60.4 0 30.2 30.2 0 0 1 60.4 0"
      fill={colors.alpha}
    />
  </svg>
);
export default Instagram;
