// tslint:disable:no-http-string
import { Link } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { SocialLink } from '../../styles/shared';
import config from '../../website-config';
import Instagram from '../icons/instagram';
import SiteNavLogo from './SiteNavLogo';

import { colors } from '../../styles/colors';

const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -20px;
  }
`;

const SiteNavStyles = css`
  position: relative;
  // z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 40px;
  font-size: 1.5rem;

  @media (max-width: 650px) {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  a svg {
    transition: transform 0.25s ease-out;
  }

  a:hover svg {
    transform: scale(1.04) rotate(4deg);
  }
`;

const SiteNavLeft = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  width: 100%;

  -ms-overflow-scrolling: touch;

  @media (max-width: 650px) {
    margin-right: 0;
    padding-left: 2vw;
    padding-bottom: 0;
    justify-content: center;
  }
`;

interface SiteNavRightProps {
  isHome: boolean;
}

const SiteNavRight = styled.div<SiteNavRightProps>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 650px) {
    margin-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    display: ${(props) => (props.isHome ? 'flex' : 'none')};
  }
`;

const NavStyles = css`
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;

  li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
    outline: none;
  }

  li a {
    display: block;
    margin: 0;
    text-decoration: ${colors.beta} underline;
    font-weight: 600;
    transition: text-decoration 0.25s;
    padding: 10px 13px; 
    font-size: 14px;
    outline: none;
  }

  @media (max-width: 450px) {
    li a {
      padding: 10px 5px;
      font-size: 1.25rem;
      text-transform: capitalize;
    }
  }

  li a:hover {
    text-decoration: ${colors.alpha} underline;
  }
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 20px;
  }
  a:first-of-type {
    padding-left: 0;
  }
`;

const HideSmall = styled.span`
  @media (max-width: 650px) {
    display: none;
  }
`;


const Camera = ({ size = 22, color = '#32BA55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g transform="translate(2 3)">
      <path d="M20 16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3l2-3h6l2 3h3a2 2 0 0 1 2 2v11z" />
      <circle cx="10" cy="10" r="4" />
    </g>
  </svg>
);

const Triangle = ({ size = 22, color = '#32BA55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 20h18L12 4z" />
  </svg>
);

const Star = ({ size = 22, color = '#32BA55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

const Image = ({ size = 22, color = '#333' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" />
    <circle cx="8.5" cy="8.5" r="1.5" />
    <path d="M20.4 14.5L16 10 4 20" />
  </svg>
);

const Edit2 = ({ size = 22, color = '#32BA55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="16 3 21 8 8 21 3 21 3 16 16 3" />
  </svg>
);

interface SiteNavProps {
  isHome?: boolean;
}

interface SiteNaveState {
  isOpen: boolean;
}

class SiteNav extends React.Component<SiteNavProps, SiteNaveState> {
  constructor(props: SiteNavProps) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const { isHome = false } = this.props;
    return (
      <nav css={[isHome && HomeNavRaise, SiteNavStyles]}>
        <SiteNavLeft>
          {!isHome && <SiteNavLogo />}
          <ul css={NavStyles} role="menu">
            {/* TODO: mark current nav item - add class nav-current */}
            <li role="menuitem">
              <Link to="/retouching/">
                Retouch
                <HideSmall>ing</HideSmall>
              </Link>
            </li>
            <li role="menuitem">
              <Link to="/photography/">
                Photo
                <HideSmall>graphy</HideSmall>
              </Link>
            </li>
            <li role="menuitem">
              <Link to="/tools/">
                Tools
              </Link>
            </li>
            <li role="menuitem">
              <Link to="/basics/">
                Basics
              </Link>
            </li>
            <li role="menuitem">
              <Link to="/gear/">
                Gear
              </Link>
            </li>
            {/* <li role="menuitem">
              <Link to="/inspiration/">Inspiration</Link>
            </li> */}
            {/* <li role="menuitem">
              <Link to="/news/">News</Link>
            </li> */}
          </ul>
        </SiteNavLeft>
        <SiteNavRight isHome={isHome}>
          <SocialLinks>
            <a
              css={SocialLink}
              href={config.instagram}
              target="_blank"
              title="Instagram"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </SocialLinks>
        </SiteNavRight>
      </nav>
    );
  }
}

export default SiteNav;
